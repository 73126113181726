import React from "react";
import * as classes from "./../../../css/productpage.module.css"
import productconstants from "./productconstants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons'



export default class ProductPage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
      return <div>
        <h1 className="productpageTitle">Produktlista</h1>
        <div className="productpageProductGrid">
        { Object.entries(productconstants.products).map((key, i) => {
        return (
            <div className="productWindow" key={i}>
                {key[1].image == undefined ? (
                    <FontAwesomeIcon icon={faVideoSlash} />
                ) : (
                    <img src={key[1].image} className="productWindowImage"/>
                )}
                <br/>
                <p className="productWindowName">{key[1].name}</p>
                <p className="productWindowName">{key[1].description}</p>
            </div>
        );
        })
        }
        </div>
      </div>;
    }
}