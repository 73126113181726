import React from "react";
import IntroductionMarketing from "./introductionmarketing";
import WhyProduct from "./whyproduct";
import ProductSchedule from "./productschedule";

export default class Startpage extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
      return <div>
        <IntroductionMarketing/>
        <WhyProduct/>
        <ProductSchedule/>
      </div>;
    }
}