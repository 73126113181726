import React from "react";
import * as classes from "./../../css/corporate.module.css"

export default class Corporate extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
      return <div className="corporateBody">
        <div className="corporateInner" itemScope itemType="https://schema.org/Organization">
            <h3><span itemProp="name">MaxSun Scandinavia AB</span></h3>
            Telefon nummer: <a className="corporatePhoneLink" href="tel:+467069319464"><span itemProp="telephone">070-69319464</span></a>
            <p>Org.nummer: 559407-7181 </p>
        </div>
      </div>;
    }
}