import React from "react";
import ContactUsButton from "../contactusbutton";
import ProductListButton from "../productlistbutton";
import * as classes from "./../../../css/introductionmarketing.module.css"



export default class IntroductionMarketing extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
      return <div className="introductionBody">
        <h1 className="introMarketingTitle">Välj framtidens el med marknadens bästa solpaneler.</h1>
        <br/>
        <br/>
        <ContactUsButton/>
        <br/>
      </div>;
    }
}