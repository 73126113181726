import React from "react";
import * as classes from "./../../css/bar.module.css"

export default class Sitebar extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
      return <nav className="navbar">
        <div className="logoInner">
            <a href="/">
                <div className="LogoDisplay"> 
                    <div className="logo"/>
                </div>
            </a>
        </div>
        <div className="siteOptions">
            <a className="siteOptionElement" href="/">
                Hem
            </a>
            |
            <a className="siteOptionElement siteOptionElementLast" href="/produkter"> 
                Produktlista
            </a>
        </div>
      </nav>;
    }
}