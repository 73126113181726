export default {
    products: {
        "15000105": {
            "name": "PV MODULE CANADIAN SOLAR 545W, MONO, PERC HALF CUT",
            "description": "",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/ca_solar.png"
        },
        "15000037": {
            "name": "PV MODULE JA SOLAR 460W, MONO, PERC HALF CUT",
            "description": "",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/ja_panel.png"
        },
        "15000099": {
            "name": "PV MODULE JINKO 400W, P-TYPE, MONO, JKM395-415M-54H",
            "description": "",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/tiger_pro_54hc_395_415.png"
        },
        "15000100": {
            "name": "PV MODULE JINKO 420W, N-TYPE, MONO, JKM410-430N-54H",
            "description": "",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/tiger_pro_54hc_395_415.png"
        },
        "15000107": {
            "name": "PV MODULE JINKO 405W, P-TYPE, MONO, JKM395-415M-54H",
            "description": "",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/tiger_pro_54hc_395_415.png"
        },
        "15000120": {
            "name": "JAM54S31 395/MR Full black",
            "description": "",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/jam54s31_380_405mr.png"
        },
        "15000121": {
            "name": "JAM54S31 405/MR Full black",
            "description": "",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/jam54s31_380_405mr.png"
        },
        "15000003": {
            "name": "SUN2000-3KTL-L1",
            "description": "Inverter, maximum 3.3KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_sec.png"
        },
        "15000014": {
            "name": "SUN2000-4KTL-L1",
            "description": "Inverter, maximum 4.4KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_sec.png"
        },
        "15000015": {
            "name": "SUN2000-5KTL-L1",
            "description": "Inverter, maximum 5.5KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_sec.png"
        },
        "15000004": {
            "name": "SUN2000-6KTL-L1",
            "description": "Inverter, maximum 6.0KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_sec.png"
        },
        "15000005": {
            "name": "SUN2000-3KTL-M1",
            "description": "Inverter, maximum 3.3KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl_4.png"
        },
        "15000006": {
            "name": "SUN2000-4KTL-M1",
            "description": "Inverter, maximum 4.4KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl_4.png"
        },
        "15000007": {
            "name": "SUN2000-5KTL-M1",
            "description": "Inverter, maximum 5.5KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl_4.png"
        },
        "15000016": {
            "name": "SUN2000-6KTL-M1",
            "description": "Inverter, maximum 6.6KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl_4.png"
        },
        "15000008": {
            "name": "SUN2000-8KTL-M1",
            "description": "Inverter, maximum 8.8KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl_4.png"
        },
        "15000009": {
            "name": "SUN2000-10KTL-M1",
            "description": "Inverter, maximum 11KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl_4.png"
        },
        "15000017": {
            "name": "SUN2000-12KTL-M2",
            "description": "Inverter, maximum 13.2KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl.png"
        },
        "15000018": {
            "name": "SUN2000-15KTL-M2",
            "description": "Inverter, maximum 16.5KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl.png"
        },
        "15000019": {
            "name": "SUN2000-17KTL-M2",
            "description": "Inverter, maximum 18.7KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl.png"
        },
        "15000020": {
            "name": "SUN2000-20KTL-M2",
            "description": "Inverter, maximum 22.0KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl.png"
        },
        "15000021": {
            "name": "SUN2000-30KTL-M3",
            "description": "Inverter, maximum 33.0KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl_2.png"
        },
        "15000022": {
            "name": "SUN2000-36KTL-M3",
            "description": "Inverter, maximum 40.0KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl_2.png"
        },
        "15000023": {
            "name": "SUN2000-40KTL-M3",
            "description": "Inverter, maximum 44.0KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl_2.png"
        },
        "15000024": {
            "name": "SUN2000-50KTL-M3",
            "description": "Inverter, maximum 55.0KVA AC Output",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/sun_ktl_3.png"
        },
        "15000010": {
            "name": "Smart Power Sensor",
            "description": "Power meter, DDSU666-H, single-phase smart meter",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/smartpowersensor.png"
        },
        "15000011": {
            "name": "Smart Power Sensor",
            "description": "Power meter, DTSU666-H, three-phase smart meter",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/smartpowersensor.png"
        },
        "15000027": {
            "name": "LUNA2000-5KW-C0",
            "description": "Power Module",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/luna.png"
        },
        "15000028": {
            "name": "LUNA2000-5-E0",
            "description": "Battery Module",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/luna.png"
        },
        "15000108": {
            "name": "SUN2000-600W-P",
            "description": "Optimizer, 600W, compatible with L1 and M1/M2/M3 inverters",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/pv_optimizer.png"
        },
        "15000031": {
            "name": "Backup Box-B0",
            "description": "Backup system",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/backupbox.png"
        },
        "15000032": {
            "name": "Backup Box-B1",
            "description": "Backup system three-phase",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/backupbox.png"
        },
        "15000033": {
            "name": "SmartLogger3000A01EU",
            "description": "Communication for 80 devices at most",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/smartlogger.png"
        },
        "15000034": {
            "name": "SmartLogger3000A03EU",
            "description": "Communication for 80 devices at most",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/smartlogger.png"
        },
        "15000036": {
            "name": "SmartDongle 4G",
            "description": "External 4G antenna, communication for 10 devices at most",
            "image": "https://pub-2a9e7d707d6c42d1a082ec2a06def9a6.r2.dev/smartdongle.png"
        },
        
    }
}