import React from "react";
import * as classes from "./../../../css/productschedule.module.css"
import ContactUsButton from "../contactusbutton";
import ProductListButton from "../productlistbutton";

export default class ProductSchedule extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
      return <div className="productBody">
        <br/>
        <div className="productDetails">
            <h2 className="productDetailTitle">
                Våra produkter
            </h2>
            <p className="productDetailDescription">Vi erbjuder en komplett lösning för att komma igång med solceller. Genom att köpa allt du behöver från en och samma leverantör, kan du vara säker på att du har de rätta komponenterna för din anläggning och att allt är kompatibelt. Vi strävar efter att göra det så enkelt som möjligt för sina kunder att komma igång med solenergi genom att erbjuda en komplett lösning.</p>
            <p className="productDetailDescription">Om du vill ha mer information om våra produkter kan du klicka på Produktlista knappen nedan.</p>

            <br/>
            <ProductListButton/>
            <br/>
            <ContactUsButton/>
        </div>
        <br/>
      </div>;
    }
}