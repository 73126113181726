import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import Sitebar from "./components/sitebar";
import Startpage from "./components/marketing/startpage";
import ProductPage from "./components/products/productpage";
import Corporate from "./components/corporate";
import * as classses from "../css/site.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Startpage/>
  },
  {
    path: "/produkter",
    element: <ProductPage/>
  }
]);

export default class App extends React.Component {
    constructor(props) {
      super(props)
    }
  
    render() {
      return (
        <React.StrictMode>
          <Sitebar/>
          <RouterProvider router={router} />
          <Corporate/>
        </React.StrictMode>
      );
    }
}