import React from "react";
import * as classes from "./../../../css/whyproduct.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSolarPanel, faTruckFast, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import ContactUsButton from "../contactusbutton";


export default class WhyProduct extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
      return <div>
        <h1 className="whyTitle">Därför är MaxSun det självklara valet!</h1>
        <div className="whyFeatures">
            <div className="whyFeature">
                <FontAwesomeIcon className="whyFeatureIcon" icon={faSolarPanel} />
                <p className="whyFeatureDescription">Vi samarbetar endast med välrenommerade tillverkare som levererar högkvalitativa solpaneler vilka framställts för lång hållbarhet.<br/><br/>&nbsp;</p>
            </div>
            <div className="whyFeature">
                <FontAwesomeIcon className="whyFeatureIcon" icon={faTruckFast} />
                <p className="whyFeatureDescription">MaxSun har en överlägsen logistik och lagerhållning av solpaneler som håller nere leveranstider till ett minimum. När du väljer MaxSun innebär det att du snabbt och enkelt får din  solenergianläggning på plats.</p>
            </div>
            <div className="whyFeature">
                <FontAwesomeIcon className="whyFeatureIcon" icon={faDollarSign} />
                <p className="whyFeatureDescription">MaxSun arbetar direkt mot tillverkare för att kunna erbjuda konkurrenskraftiga priser och snabbast leverans. Våra etablerade relationer med tillverkaren gör det möjligt för oss att erbjuda solpaneler till bästa pris.</p>
            </div>
        </div>
        
        <br/>
      </div>;
    }
}