import React from "react";
import * as classes from "./../../css/contactusbutton.module.css"

export default class ContactUsButton extends React.Component {
    constructor(props) {
        super(props)
        console.log(classes)
    }

    render() {
      return <a className="contactButton" href="mailto:test@maxsun.se">
        <div className="contactInner">
            <p>Kontakta oss</p>
        </div>
      </a>;
    }
}