import React from "react";
import { Link } from "react-router-dom";
import * as classes from "./../../css/productlistbutton.module.css"

export default class ProductListButton extends React.Component {
    constructor(props) {
        super(props)
        console.log(classes)
    }

    render() {
      return <Link className="productListButton" to="/produkter">
        <div className="productButtonInner">
            <p>Produktlista</p>
        </div>
      </Link>;
    }
}